<template>
  <div>
    <div>
      <b-button
        block
        :variant="$store.state.appConfig.layout.skin === 'dark' ? 'dark' : ''"
        class="d-md-none mb-1"
        @click="isHidden = !isHidden"
      >
        <div>
          <feather-icon
            icon="MenuIcon"
            :class="$store.state.appConfig.layout.isRTL ? 'left-position' : 'right-position'"
            size="20"
          />
        </div>
      </b-button>
      <b-card
        class="mb-2 rounded remove-padding"
        :class="(isHidden ? 'd-md-block  d-none' : '')"
      >
        <b-tabs
          v-model="tabNumber"
          :class="$store.state.appConfig.layout.skin === 'dark' ? 'custom-tab-bg-blue' :'custom-tab-bg-aliceblue'"
          content-class="col-12 col-md-12 mt-1 mt-md-0"
          pills
          nav-wrapper-class="col-md-12 col-12"
          class="row forex-tab"
          @input="findTab"
        >
          <b-tab
            v-for="tab in filtredtabsItem"
            :key="tab.number+'forextabs'"
            active
            @click="tabClick(tab)"
          >
            <!-- title -->
            <template
              #title
            >
              <div :class="$store.state.appConfig.layout.skin === 'dark' ? 'text-white' :''">
                <feather-icon
                  :icon="tab.icon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold default">
                  {{ $t(tab.title) }}
                </span>
              </div>
            </template>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>
import { BTabs, BTab } from 'bootstrap-vue'

export default {
  components: {
    BTabs,
    BTab,
  },
  data() {
    return {
      isHidden: false,
      tabNumber: 0,
      tabsItem: [
        {
          title: 'forex.trade-history',
          link: { name: 'forexModule-trade-History', params: { platform: this.$route.params.platform, id: this.$route.params.id } },
          number: 0,
          icon: 'CornerUpLeftIcon',
        },

        {
          title: 'forex.transfer-history',
          link: { name: 'forexModule-DW-History', params: { platform: this.$route.params.platform, id: this.$route.params.id } },
          number: 1,
          icon: 'CornerUpLeftIcon',
        },
        {
          title: 'forex.internal-transfer',
          link: { name: 'forexModule-transfer-account-balance', params: { platform: this.$route.params.platform, id: this.$route.params.id } },
          number: 2,
          icon: 'RepeatIcon',
        },
        {
          title: 'forex.change-password',
          link: { name: 'forexModuleaccount-change-password', params: { platform: this.$route.params.platform, id: this.$route.params.id } },
          number: 3,
          icon: 'Edit2Icon',
        },
      ],
    }
  },
  computed: {
    filtredtabsItem() {
      return this.tabsItem.filter(tab => !tab.hidden || tab.hidden !== true)
    },
  },
  methods: {
    areObjectsEqual(obj1, obj2) {
      // Check if both arguments are objects
      if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return false
      }

      // Get the keys of both objects
      const keys1 = Object.keys(obj1)
      const keys2 = Object.keys(obj2)

      // Check if the number of keys is different
      if (keys1.length !== keys2.length) {
        return false
      }

      // Check if all keys and values in obj1 exist in obj2
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys1) {
        if (obj2[key] !== obj1[key]) {
          return false
        }
      }

      return true
    },
    findTab() {
      this.$nextTick(() => {
        const routeParams = JSON.parse(JSON.stringify(this.$route.params))
        delete routeParams.lang
        const tabNumber = this.tabsItem.find(tab => (tab.link.name === this.$route.name
           && this.areObjectsEqual(routeParams, tab.link.params))).number
        if (tabNumber || tabNumber === 0) {
          this.tabNumber = tabNumber
        } else {
          throw new Error('cant find tab number')
        }
      })
    },
    tabClick(tab) {
      this.$router.push(tab.link)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep.router-link-exact-active {
  color:white
}
.custom-tab-bg-aliceblue ::v-deep.nav-pills .nav-link.active{
background-color: $primary !important;
}
.custom-tab-bg-aliceblue ::v-deep.nav-pills .nav-link{
background-color: aliceblue  ;

}
.custom-tab-bg-blue ::v-deep.nav-pills .nav-link{
background-color: rgb(155, 190, 221);

}
[dir] .tabs ::v-deep.nav-pills .nav-link{
margin: 3px !important;
}
.remove-padding .card-body{
padding: 0;
}
</style>
